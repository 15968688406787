import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import styles from './App.module.scss';

class App extends Component {
  render() {
    return (
      <div className={styles.app}>
        <header className={styles.header}>
          <FontAwesomeIcon fixedWidth size="10x" spin icon={faCog} />
          <h1>Website Under Development</h1>
          <h3>Please come back again soon.</h3>
          <p>
            In the meantime, please follow us on{' '}
            <a href="//www.facebook.com/moversearchitects">Facebook</a>.
          </p>
        </header>
        <footer className={styles.footer}>
          <div className="container">
            <span className="text-muted">
              This website is currently under development by{' '}
              <a href="//www.achromex.com">Achromex Co., Ltd.</a>
            </span>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
